import React from 'react';
import { useNavigate } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import './HomeCard.css';

function HomeCard({ title, redirect }) {
  const navigate = useNavigate();

  return (
    <Card className='HomeCard'>
      <Card.Body>
        <Card.Title>{title}</Card.Title>
        <Card.Text>
          Some quick example text to build on the card title and make up the bulk of the cards
          content.
        </Card.Text>
        <Button variant='primary' onClick={() => navigate(redirect)}>
          Open
        </Button>
      </Card.Body>
    </Card>
  );
}

export default HomeCard;
