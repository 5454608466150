import React from 'react';
import SearchBar from './SearchBar';
import FileBrowser from './FileBrowser';
import './SidePane.css';

export default function SidePane({
  filteredCompanies,
  currentPlatform,
  setCurrentPlatform,
  searchTerm,
  setSearchTerm,
}) {
  return (
    <div className='SidePane'>
      <SearchBar searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
      <FileBrowser
        filteredCompanies={filteredCompanies}
        currentPlatform={currentPlatform}
        setCurrentPlatform={setCurrentPlatform}
      />
    </div>
  );
}
