import config from '../../config.js';
import axios from 'axios';

const getAllDomainsData = async (cookies) => {
  const url = `${config.backendUrl}/domains-data`;
  const axiosConfig = {
    headers: { Authorization: `Bearer ${cookies.access_token}` },
  };
  const overallUsersCount = await axios.get(url, axiosConfig);
  return overallUsersCount.data;
};

const createOneDomainData = async (cookies, domainData) => {
  const url = `${config.backendUrl}/domains-data`;
  const axiosConfig = {
    headers: { Authorization: `Bearer ${cookies.access_token}` },
  };

  const overallUsersCount = await axios.post(url, domainData, axiosConfig);
  return overallUsersCount.data;
};

const updateOneDomainsData = async (cookies, id, domainData) => {
  const url = `${config.backendUrl}/domains-data/${id}`;
  const axiosConfig = {
    headers: { Authorization: `Bearer ${cookies.access_token}` },
  };

  const overallUsersCount = await axios.put(url, domainData, axiosConfig);
  return overallUsersCount.data;
};

const updateAllDomainsData = async (cookies, domainsData) => {
  const url = `${config.backendUrl}/domains-data`;
  const axiosConfig = {
    headers: { Authorization: `Bearer ${cookies.access_token}` },
  };
  const body = {
    domainsData,
  };
  const overallUsersCount = await axios.put(url, body, axiosConfig);
  return overallUsersCount.data;
};

const deleteOneDomainsData = async (cookies, id) => {
  const url = `${config.backendUrl}/domains-data/${id}`;
  const axiosConfig = {
    headers: { Authorization: `Bearer ${cookies.access_token}` },
  };

  const overallUsersCount = await axios.delete(url, axiosConfig);
  return overallUsersCount.data;
};

export {
  getAllDomainsData,
  createOneDomainData,
  updateAllDomainsData,
  updateOneDomainsData,
  deleteOneDomainsData,
};
