import React from 'react';
import { Table, Button } from 'react-bootstrap';

function CategoryTable({ categoryData, industryData, onRemoveCategory, onRemoveIndustry }) {
  return (
    <>
      <Table responsive bordered hover className='TagTable'>
        <thead>
          <tr>
            <td>Industries</td>
          </tr>
        </thead>
        <tbody>
          {/* <div style={{ display: 'flex', flexWrap: 'wrap', gap: '0.25rem' }}> */}
          {industryData?.map((industry, index) => (
            <tr key={index}>
              <td>
                <div className='tag-container'>
                  <Button
                    variant='secondary'
                    size='sm'
                    className='tag-button'
                    onClick={() => onRemoveIndustry(industry)}
                  >
                    {industry?.hierarchy}
                    <span className='remove-button'>x</span>
                  </Button>
                </div>
              </td>
            </tr>
          ))}
          {/* </div> */}
        </tbody>
      </Table>
      <Table responsive bordered hover className='TagTable'>
        <thead>
          <tr>
            <td>Categories</td>
          </tr>
        </thead>
        <tbody>
          {categoryData?.map((categoryItem, index) => (
            <tr key={index}>
              <td>
                <div className='tag-container'>
                  <Button
                    variant='secondary'
                    size='sm'
                    className='tag-button'
                    onClick={() => onRemoveCategory(categoryItem?.id)}
                  >
                    {categoryItem?.hierarchy}
                    <span className='remove-button'>x</span>
                  </Button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
}

export default CategoryTable;
