export const highlighterHtml = `
<style>
  body{
      overflow-x:hidden;
  }
  .label-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .label {
    position: absolute;
          left:0;
          cursor: pointer;
    background-color: #FDFF47;
  }
  .hidden {
    display: none;
  }
  .highlighted {
    box-shadow: 0 0 0 1px red;
    cursor: pointer;
  }
</style>
<script>
  document.addEventListener("DOMContentLoaded", function() {
    dragElement();
  });
  function Xpath(el) 
  {
      if (typeof el === "string") return document.evaluate(el, document, null, 0, null)
      if (!el || el.nodeType !== 1) return ''
      //if (el.id) return "//*[@id='" + el.id + "']"
      var sames = [].filter.call(el.parentNode.children, function (x) { return x.tagName === el.tagName })
      return Xpath(el.parentNode) + '/' + el.tagName.toLowerCase() + (sames.length > 1 ? '['+([].indexOf.call(sames, el)+1)+']' : '')
  }
  function dragElement() {
      var pos1 = 0, pos2 = 0, pos3 = 0, pos4 = 0;
      var elements = document.getElementsByClassName("label");

      for (var i = 0; i < elements.length; i++) {
          var elmnt = elements[i];
          elmnt.onmousedown = dragMouseDown;
      }
    
      function dragMouseDown(e) {
        e = e || window.event;
        e.preventDefault();
        // get the mouse cursor position at startup:
        pos3 = e.clientX;
        pos4 = e.clientY;
        document.onmouseup = closeDragElement;
        // call a function whenever the cursor moves:
        document.onmousemove = elementDrag;
      }
    
      function elementDrag(e) {
        e = e || window.event;
        e.preventDefault();
        // calculate the new cursor position:
        pos1 = pos3 - e.clientX;
        pos2 = pos4 - e.clientY;
        pos3 = e.clientX;
        pos4 = e.clientY;
        // set the element's new position:
        elmnt.style.top = (elmnt.offsetTop - pos2) + "px";
        elmnt.style.left = (elmnt.offsetLeft - pos1) + "px";
      }
    
      function closeDragElement() {
        // stop moving when mouse button is released:
        document.onmouseup = null;
        document.onmousemove = null;
      }
    }
  document.addEventListener('mouseover', event => {
      if (!event.target.classList.contains("label")) 
      {
          event.target.classList.add('highlighted');
      }
  });
  document.addEventListener('mouseout', event => {
      if(!event.target.hasAttribute("data-status"))
      {
          event.target.classList.remove('highlighted');
      }
  });
  document.addEventListener('click', event => {
      event.preventDefault();
      const element = event.target;
      const xpath = Xpath(element);
      var clone = document.createElement('div');
      clone.classList.add('label-wrapper');
      clone.innerHTML= '<div class="label">&nbsp;Label&nbsp;</div><div class="labeled-box"></div>'
      if(!event.target.hasAttribute("data-status") && !event.target.classList.contains("label"))
              {
          element.classList.add('highlighted');
          element.setAttribute("data-status","set");
          document.body.appendChild(clone);
          const rect = element.getBoundingClientRect();
          clone.style.position = "absolute";
          clone.classList.add("label");
          clone.style.top = rect.top - 20 + window.scrollY + "px";
          clone.style.left = rect.left + "px";
          clone.id=xpath;
          var label_name=prompt("Please enter the Label name");
          clone.firstElementChild.innerHTML = "&nbsp;" + label_name.toLowerCase() + "&nbsp;";
          const message = JSON.stringify({
          name: label_name,
          xpath: xpath,
          doc: document.documentElement.innerHTML,
          });
          window.parent.postMessage(message, '*');
      }
      else if(event.target.hasAttribute("data-status"))
      {
          element.removeAttribute("data-status");
          event.target.classList.remove('highlighted');
          var currclone = document.getElementById(xpath);
          currclone.remove();
          const message = JSON.stringify({
              name: label_name,
              xpath: "",
              doc: document.documentElement.innerHTML,
              });
          window.parent.postMessage(message, '*');
      }
});
  </script>
</html>`;