import React from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { Search } from 'react-bootstrap-icons';
import './SearchBar.css';

export default function SearchBar({ setSearchTerm }) {
  const handleSearchBarChange = (e) => {
    e.preventDefault();
    setSearchTerm(e.target.value);
  };

  const handleSearch = (e) => {
    e.preventDefault();
  };

  return (
    <div className='SearchBar'>
      <Form className='d-flex'>
        <Form.Control
          type='search'
          placeholder='Search'
          className='me-1'
          aria-label='Search'
          onChange={handleSearchBarChange}
          onSubmit={handleSearch}
        />
        <Button variant='outline-primary' onClick={handleSearch}>
          <Search />
        </Button>
      </Form>
    </div>
  );
}
