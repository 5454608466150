import React from 'react';
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
import './FileBrowser.css';

export default function FileBrowser({ filteredCompanies, currentPlatform, setCurrentPlatform }) {
  function handleDirClick(platform) {
    setCurrentPlatform(platform);
  }

  return (
    <Card className='FileBrowser'>
      {/* Breadcrumb */}
      <Card.Header className='Header'>Available Companies</Card.Header>

      {/* filteredCompanies */}
      <div className='Body'>
        <ListGroup className='ListGroup'>
          {filteredCompanies.map((platform, idx) => {
            return (
              <ListGroup.Item
                className='Row'
                name={platform}
                index={idx}
                key={idx}
                onClick={() => handleDirClick(platform)}
                active={platform === currentPlatform}
              >
                {platform}
              </ListGroup.Item>
            );
          })}
        </ListGroup>
      </div>

      {/* Pagination buttons  */}
      <Card.Footer className='Footer'>Footer</Card.Footer>
    </Card>
  );
}
