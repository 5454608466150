import config from '../../config.js';
import axios from 'axios';

const getOverallUsersCount = async (cookies) => {
  const url = `${config.backendUrl}/instaclean-kpi/users/overall`;
  const axiosConfig = {
    headers: { Authorization: `Bearer ${cookies.access_token}` },
  };
  const overallUsersCount = await axios.get(url, axiosConfig);
  return overallUsersCount.data;
};

const getDailyUsersCount = async ({ cookies, startDate, endDate }) => {
  const url = `${config.backendUrl}/instaclean-kpi/users/daily?start_date=${startDate}&end_date=${endDate}`;
  const axiosConfig = {
    headers: { Authorization: `Bearer ${cookies.access_token}` },
  };
  const dailyCounts = await axios.get(url, axiosConfig);
  return dailyCounts.data;
};

export { getOverallUsersCount, getDailyUsersCount };
