import React, { useState, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import SidePane from './SidePane';
import MainPane from './MainPane';
import { getAllCompanies, getCollectionSchema, getIndustriesSchema } from './api';
import './MailsightsAdmin.css';

export default function MailsightsAdmin() {
  const [cookies] = useCookies(['access_token']);
  const [promotionType, setPromotionType] = useState('pending');
  const [countryCode, setCountryCode] = useState('US');
  const [categories, setCategories] = useState('');
  const [industries, setIndustries] = useState('');
  const [categoriesSchema, setCategoriesSchema] = useState([]);
  const [industriesSchema, setIndustriesSchema] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [filteredCompanies, setFilteredCompanies] = useState([]);
  const [currentPlatform, setCurrentPlatform] = useState('');
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    async function fetchPlatforms() {
      const response = await getAllCompanies(cookies);
      const companies = response.data.companies;
      setCompanies(companies);
      setFilteredCompanies(companies);
    }
    fetchPlatforms();
  }, []);

  useEffect(() => {
    async function fetchCollectionSchema() {
      const response = await getCollectionSchema(cookies);
      const categories = response.data.categories;

      // const analyticsDataIds = [
      //   'f0ea070b-b59c-4786-8fa9-105d8b6d1331',
      //   'ea19a6ee-7e3e-4665-a4b4-7b0bf2b56e48',
      //   '5a55c7bb-5d98-48ee-9f76-633650e2e5fe',
      //   '65ecec91-4b6b-4a29-8733-723395410ec6',
      //   'a227bcda-5a3c-4466-b85f-f28c3d5746e4',
      // ];

      // const categoriesWihtoutAnalytics = categories.filter(
      //   (category) => !analyticsDataIds.includes(category.id),
      // );
      // console.log(categoriesWihtoutAnalytics);

      setCategoriesSchema(categories);
      // setCategoriesSchema(categoriesWihtoutAnalytics);
    }
    fetchCollectionSchema();
  }, []);

  useEffect(() => {
    async function fetchIndustriesSchema() {
      const response = await getIndustriesSchema(cookies);
      const industries = response.data.industries;
      setIndustriesSchema(industries);
    }
    fetchIndustriesSchema();
  }, []);

  useEffect(() => {
    const filteredCompanies = companies.filter((company) => {
      return company.toLowerCase().includes(searchTerm.toLowerCase());
    });
    setFilteredCompanies(filteredCompanies);
  }, [searchTerm]);

  return (
    <div className='MailsightsAdmin'>
      <SidePane
        filteredCompanies={filteredCompanies}
        currentPlatform={currentPlatform}
        setCurrentPlatform={setCurrentPlatform}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
      />
      <MainPane
        categoriesSchema={categoriesSchema}
        industriesSchema={industriesSchema}
        promotionType={promotionType}
        setPromotionType={setPromotionType}
        currentPlatform={currentPlatform}
        countryCode={countryCode}
        setCountryCode={setCountryCode}
        categories={categories}
        setCategories={setCategories}
        industries={industries}
        setIndustries={setIndustries}
      />
    </div>
  );
}
