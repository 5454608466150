import config from '../../config.js';
import axios from 'axios';

const getAllCompanies = async (cookies) => {
  const url = `${config.backendUrl}/mailsights-admin/companies`;
  const axiosConfig = {
    headers: { Authorization: `Bearer ${cookies.access_token}` },
  };
  const response = await axios.get(url, axiosConfig);
  return response.data;
};

const getCollectionSchema = async (cookies) => {
  const url = `${config.backendUrl}/mailsights-admin/categories`;
  const axiosConfig = {
    headers: { Authorization: `Bearer ${cookies.access_token}` },
  };
  const response = await axios.get(url, axiosConfig);
  console.log(response.data);
  return response.data;
};

const getIndustriesSchema = async (cookies) => {
  const url = `${config.backendUrl}/mailsights-admin/industries`;
  const axiosConfig = {
    headers: { Authorization: `Bearer ${cookies.access_token}` },
  };
  const response = await axios.get(url, axiosConfig);
  console.log(response.data);
  return response.data;
};

const getCompanyEmailsData = async ({
  cookies,
  currentCompany,
  limit = 20,
  offset = 0,
  promotionType,
  countryCode,
  category,
  industry,
}) => {
  const url = `${
    config.backendUrl
  }/mailsights-admin/companies/data?company_name=${currentCompany}&limit=${limit}&offset=${offset}&country_code=${countryCode}&approval_status=${promotionType}&category=${encodeURIComponent(
    category,
  )}&industry=${industry}`;

  const axiosConfig = {
    headers: { Authorization: `Bearer ${cookies.access_token}` },
  };
  const response = await axios.get(url, axiosConfig);
  return response.data;
};

const getCompanyEmailsCount = async (
  cookies,
  currentCompany,
  promotionType,
  countryCode,
  category,
  industry,
) => {
  const url = `${
    config.backendUrl
  }/mailsights-admin/companies/count?country_code=${countryCode}&category=${encodeURIComponent(
    category,
  )}&industry=${industry}&company_name=${currentCompany}&approval_status=${promotionType}`;

  const axiosConfig = {
    headers: { Authorization: `Bearer ${cookies.access_token}` },
  };
  const response = await axios.get(url, axiosConfig);
  return response.data;
};

const getEmailContent = async (cookies, filePath) => {
  const filePathEncoded = encodeURIComponent(filePath);
  const url = `${config.backendUrl}/mailsights-admin/promotions/${filePathEncoded}`;
  const axiosConfig = {
    headers: { Authorization: `Bearer ${cookies.access_token}` },
  };
  const response = await axios.get(url, axiosConfig);
  return response.data;
};

const putHTML = async (cookies, filePath, htmlString) => {
  const filePathEncoded = encodeURIComponent(filePath);
  const url = `${config.backendUrl}/mailsights-admin/promotions/${filePathEncoded}/html`;
  const axiosConfig = {
    headers: { Authorization: `Bearer ${cookies.access_token}` },
  };
  const body = {
    updated_html: htmlString,
  };
  const response = await axios.put(url, body, axiosConfig);
  return response;
  // return {
  //   data: 'some data',
  //   status: 404,
  // };
};

const approvePromos = async (cookies, promoIds) => {
  const url = `${config.backendUrl}/mailsights-admin/promotions/status`;
  const axiosConfig = {
    headers: { Authorization: `Bearer ${cookies.access_token}` },
  };
  const body = {
    promo_ids: promoIds,
    approval_status: 'approved',
  };
  const response = await axios.post(url, body, axiosConfig);
  return response.data;
};

const rejectPromos = async (cookies, promoIds) => {
  const url = `${config.backendUrl}/mailsights-admin/promotions/status`;
  const axiosConfig = {
    headers: { Authorization: `Bearer ${cookies.access_token}` },
  };
  const body = {
    promo_ids: promoIds,
    approval_status: 'rejected',
  };
  const response = await axios.post(url, body, axiosConfig);
  return response.data;
};

const reviewPromos = async (cookies, promoIds) => {
  const url = `${config.backendUrl}/mailsights-admin/promotions/status`;
  const axiosConfig = {
    headers: { Authorization: `Bearer ${cookies.access_token}` },
  };
  const body = {
    promo_ids: promoIds,
    approval_status: 'review',
  };
  const response = await axios.post(url, body, axiosConfig);
  return response.data;
};

const updateCollection = async (cookies, promoId, updatedCollection) => {
  const url = `${config.backendUrl}/mailsights-admin/promotions/update-collection`;
  const axiosConfig = {
    headers: { Authorization: `Bearer ${cookies.access_token}` },
  };
  const body = {
    promo_id: promoId,
    collection_subcollection: updatedCollection,
  };
  const response = await axios.post(url, body, axiosConfig);
  // console.log(response);
  return response;
  // console.log('API CALL TO UPDATE COLLECTION', promoId, updatedCollection);
  // return {
  //   data: 'some data',
  //   status: 204,
  // };
};

const putCategories = async (cookies, promoId, categories) => {
  console.log(typeof categories);
  const url = `${config.backendUrl}/mailsights-admin/promotions/category`;
  const axiosConfig = {
    headers: { Authorization: `Bearer ${cookies.access_token}` },
  };
  const body = {
    promo_id: promoId,
    category_ids: [...categories],
  };
  const response = await axios.put(url, body, axiosConfig);

  return response;

  // console.log('Categories Sent!!!');
  // console.log(cookies, promoId, categories);
  // return {
  //   status: 200,
  //   data: 'Updated',
  // };
};

const putIndustry = async (cookies, promoId, industry) => {
  console.log(typeof industry);
  const url = `${config.backendUrl}/mailsights-admin/promotions/industry`;
  const axiosConfig = {
    headers: { Authorization: `Bearer ${cookies.access_token}` },
  };
  const body = {
    promo_id: promoId,
    industry_ids: industry,
  };
  const response = await axios.put(url, body, axiosConfig);

  return response;

  // console.log('Industry Sent!!!');
  // console.log(cookies, promoId, industry);
  // return {
  //   status: 200,
  //   data: 'Updated',
  // };
};

export {
  getAllCompanies,
  getCollectionSchema,
  getIndustriesSchema,
  getCompanyEmailsData,
  getCompanyEmailsCount,
  getEmailContent,
  approvePromos,
  rejectPromos,
  reviewPromos,
  updateCollection,
  putCategories,
  putIndustry,
  putHTML,
};
