import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';

import './InstacleanKpi.css';

import { getDailyUsersCount, getOverallUsersCount } from './api';

function InstacleanKpi() {
  const [cookies] = useCookies(['access_token']);
  const [allUsersCount, setAllUsersCount] = useState(null);
  const [activeUsersCount, setActiveUsersCount] = useState(null);
  const [inactiveUsersCount, setInactiveUsersCount] = useState(null);

  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [tableData, setTableData] = useState({});
  const [loading, setLoading] = useState(false); // New loading state

  const handleSubmit = async () => {
    // Set loading to true when the API request starts
    setLoading(true);

    try {
      console.log('Start Date:', startDate);
      console.log('End Date:', endDate);

      const response = await getDailyUsersCount({ cookies, startDate, endDate });
      setTableData(response.data);
    } finally {
      // Set loading to false regardless of the success or failure of the API request
      setLoading(false);
    }
  };

  useEffect(() => {
    (async () => {
      const response = await getOverallUsersCount(cookies);
      if (response.statusCode === 200) {
        setAllUsersCount(response.data.allUsersCount);
        setActiveUsersCount(response.data.activeUsersCount);
        setInactiveUsersCount(response.data.inactiveUsersCount);
      }
    })();
  }, []);

  useEffect(() => {
    console.log(tableData);
  }, [tableData]);

  return (
    <div className='InstacleanKpi'>
      <div className='kpi-container'>
        <div className='half overall-counts-container'>
          <p>Overall Users: {allUsersCount}</p>
          <p>Active Users: {activeUsersCount}</p>
          <p>Inactive Users: {inactiveUsersCount}</p>
        </div>
        <div className='half daily-counts-container'>
          <div className='daily-user'>
            <div className='daily-user__input'>
              {/* Date input fields */}
              <input type='date' value={startDate} onChange={(e) => setStartDate(e.target.value)} />
              <input type='date' value={endDate} onChange={(e) => setEndDate(e.target.value)} />
              {/* Submit button */}
              <button
                disabled={startDate === '' || endDate === '' || loading}
                onClick={handleSubmit}
              >
                {loading ? 'Loading...' : 'Submit'}
              </button>
            </div>
            <div className='daily-user__table'>
              {/* Scrollable table */}
              {/* Table with 3 columns */}
              <table>
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Registered Users</th>
                    <th>Churned Users</th>
                    <th>Deleted Users</th>
                  </tr>
                </thead>
                <tbody>
                  {tableData?.registeredUsers?.map((row, index) => {
                    const currentChurnUser = tableData?.churnedUsers?.find(
                      (item) => item['_id'] === row._id,
                    );
                    const currentDeletedUser = tableData?.deletedUsers?.find(
                      (item) => item['_id'] === row._id,
                    );
                    console.log(
                      `Row: ${JSON.stringify(row)}\nCurrent: ${JSON.stringify(currentChurnUser)}`,
                    );
                    return (
                      <tr key={index}>
                        <td>{row._id}</td>
                        <td>{row.count}</td>
                        <td>
                          {(currentChurnUser !== undefined && currentChurnUser?.count) || '-'}
                        </td>
                        <td>
                          {(currentDeletedUser !== undefined && currentDeletedUser?.count) || '-'}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default InstacleanKpi;
