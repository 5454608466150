import React from 'react';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Button from 'react-bootstrap/Button';
import { useNavigate, useLocation } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import jwtDecode from 'jwt-decode';
import './Navbar.css';

function BasicExample() {
  const navigate = useNavigate();
  const location = useLocation();
  const currentPage = location.pathname;
  const [cookies] = useCookies(['access_token']);
  const [email, setEmail] = useState('');
  const [userType, setUserType] = useState('');
  useEffect(() => {
    if (cookies.access_token) {
      let decodedToken = jwtDecode(cookies.access_token);
      setEmail(decodedToken.email);
      setUserType(decodedToken.roles[0]);
    }
  }, [location]);

  return (
    <Navbar className='Navbar' bg='dark' variant='dark'>
      <Container>
        <Navbar.Brand href='/'>Vumonic - Internal Tool</Navbar.Brand>

        {currentPage !== '/login' ? (
          <Nav className='justify-content-end'>
            <Navbar.Text className='NavbarText'>
              Singed in as:{' '}
              <b>{email.split('@')[0].charAt(0).toUpperCase() + email.split('@')[0].slice(1)}</b>
            </Navbar.Text>

            {userType === 'admin' && (
              <Nav.Link onClick={() => navigate('/register')} className='NavbarText'>
                Add New User
              </Nav.Link>
            )}

            <Button variant='danger' onClick={() => navigate('/logout')}>
              Logout
            </Button>
          </Nav>
        ) : (
          <></>
        )}
      </Container>
    </Navbar>
  );
}

export default BasicExample;
