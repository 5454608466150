import React from 'react';
import { useCookies } from 'react-cookie';
import { Navigate, useSearchParams } from 'react-router-dom';

export default function VerifyToken() {
  const [queryParams, setQueryParams] = useSearchParams();
  const [, setCookie] = useCookies(['access_token']);

  const token = queryParams.get('token');
  const expiry = queryParams.get('expiry');

  if (token && expiry) {
    setCookie('access_token', token, {
      path: '/',
      maxAge: expiry,
    });
    return <Navigate to={'/'} />;
  }

  return <Navigate to={'/invalid-login'} />;
}
