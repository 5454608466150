import axios from 'axios';
import config from '../../config.js';

const AUTH_ROUTE = config.backendUrl;

async function login(email, password) {
  try {
    const response = await axios.post(`${AUTH_ROUTE}/auth/login`, {
      email: email,
      password: password,
      role: 'user',
    });
    let error = false;
    let message = 'success';
    let accessToken = '';
    if (response.status === 200) {
      accessToken = response.data.data.access_token;
    } else if (response.status === 401) {
      error = true;
      message = 'Invalid credentials';
    } else {
      error = true;
      message = response.data.data.message;
    }
    return { error, message, accessToken };
  } catch (error) {
    console.error(error);
    return { error, message: error.message, accessToken: '' };
  }
}

async function googleLogin() {
  window.open(`${AUTH_ROUTE}/auth/google`, '_self');
}

async function register(name, email, password, role, token) {
  let response;
  try {
    response = await axios.post(
      `${AUTH_ROUTE}/users`,
      {
        name: name,
        email: email,
        password: password,
        roles: role,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    if (response.status !== 401) {
      return { message: 'Success' };
    } else {
      console.log('Registration failed');
    }
  } catch (error) {
    if (error.response?.status === 401) {
      console.log('Unauthorized: Invalid credentials');
      return { message: 'Error', type: 'Invalid credentials' };
    } else {
      console.log('An error occurred during login:', error);
      return { message: 'Error', type: error };
    }
  }
}

async function logout() {
  try {
    const result = await axios.get(`${AUTH_ROUTE}/logout`);
    return result;
  } catch (err) {
    console.log(err);
  }
}

async function isAuthenticated() {
  try {
    const result = await axios.get(`${AUTH_ROUTE}/authenticated`);
    if (result.status !== 401) return result.data;
    else
      return {
        isAuthenticated: false,
        user: { name: '', email: '', role: '' },
      };
  } catch (err) {
    console.log(err);
  }
}

export { login, googleLogin, register, logout, isAuthenticated };
