import React, { useState } from 'react';
import { useCookies } from 'react-cookie';
import RenderMasterSidePane from './RenderMasterSidePane';
import RenderMasterMainPane from './RenderMasterMainPane';
import './RenderMaster.css';

export default function RenderMaster() {
  const [cookie] = useCookies(['access_token']);
  const [breadcrumb, setBreadcrumb] = useState([]);
  const [currentPage, setCurrentPage] = useState('');
  const [nextPage, setNextPage] = useState('');
  const [dirs, setDirs] = useState([]);
  const [files, setFiles] = useState([]);
  const [fileName, setFileName] = useState('');
  const [fileContent, setFileContent] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  return (
    <div className='RenderMaster'>
      <RenderMasterSidePane
        cookie={cookie}
        breadcrumb={breadcrumb}
        setBreadcrumb={setBreadcrumb}
        dirs={dirs}
        setDirs={setDirs}
        files={files}
        setFiles={setFiles}
        fileName={fileName}
        setFileName={setFileName}
        fileContent={fileContent}
        setFileContent={setFileContent}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        nextPage={nextPage}
        setNextPage={setNextPage}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
      />
      <RenderMasterMainPane fileContent={fileContent} />
    </div>
  );
}
