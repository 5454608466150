import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { Search } from 'react-bootstrap-icons';
import './RenderMasterSidePaneSearch.css';

export default function SidePaneSearch({ searchDirAndFiles }) {
  const [searchTerm, setSearchTerm] = useState('');

  const handleSearchBarChange = (e) => {
    e.preventDefault();
    const prefix = e.target.value;
    setSearchTerm(prefix);
  };

  const handleSearch = (e) => {
    e.preventDefault();
    searchDirAndFiles(searchTerm);
  };

  return (
    <div className='SidePaneSearch'>
      <Form className='d-flex' onSubmit={handleSearch}>
        <Form.Control
          type='search'
          placeholder='Search'
          className='me-1'
          aria-label='Search'
          onChange={handleSearchBarChange}
          onSubmit={handleSearch}
        />
        <Button variant='outline-primary' onClick={handleSearch}>
          <Search />
        </Button>
      </Form>
    </div>
  );
}
