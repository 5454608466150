import React, { useState, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import DataTable from 'react-data-table-component';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { PencilSquare, Plus, Trash } from 'react-bootstrap-icons';
import { DateTime } from 'luxon';
import {
  getAllDomainsData,
  createOneDomainData,
  updateOneDomainsData,
  deleteOneDomainsData,
} from './api';
import './DomainsData.css';

export default function DomainsData() {
  const [cookies] = useCookies(['access_token']);
  const [domainsData, setDomainsData] = useState([]);
  const [currentDomainData, setCurrentDomainData] = useState({});
  const [currentCurrentCompanyName, setCurrentCurrentCompanyName] = useState('');
  const [currentVerticalName, setCurrentVerticalName] = useState('');
  const [currentPossibleDomains, setCurrentPossibleDomains] = useState('');
  const [currentEmailType, setCurrentEmailType] = useState('');
  const [currentSubjectKeywords, setCurrentSubjectKeywords] = useState('');
  const [loading, setLoading] = useState(true);
  const [modalShow, setModalShow] = useState(false);
  const [modalMode, setModalMode] = useState('create'); // edit and create

  useEffect(() => {
    (async () => {
      await fetchAndUpdateDomainsData();
    })();
  }, []);

  useEffect(() => {
    setCurrentCurrentCompanyName(currentDomainData.company_name);
    setCurrentVerticalName(currentDomainData.vertical_name);
    setCurrentEmailType(currentDomainData.email_type);
    setCurrentPossibleDomains(currentDomainData.possible_domains);
    setCurrentSubjectKeywords(currentDomainData.subject_keywords);
  }, [currentDomainData]);

  const fetchAndUpdateDomainsData = async () => {
    setLoading(true);
    const response = await getAllDomainsData(cookies);
    setDomainsData(response.data);
    setLoading(false);
  };

  // Single Row Full Info handler
  const handleOpenModal = (e, modalMode, emailData) => {
    e.preventDefault();
    setModalMode(modalMode);
    setCurrentDomainData(emailData);
    setModalShow(true);
  };

  const handleDeleteRow = async (row) => {
    await deleteOneDomainsData(cookies, row.id);
    await fetchAndUpdateDomainsData();
  };

  const handleModalUpdateData = async (e) => {
    e.preventDefault();
    const domainData = {};
    const id = currentDomainData.id;
    domainData.company_name = currentCurrentCompanyName || '';
    domainData.vertical_name = currentVerticalName || '';
    domainData.possible_domains =
      (currentPossibleDomains && currentPossibleDomains.split(',')) || [];
    domainData.email_type = currentEmailType || '';
    domainData.subject_keywords =
      (currentSubjectKeywords && currentSubjectKeywords.split(',')) || [];
    await updateOneDomainsData(cookies, id, domainData);
    await fetchAndUpdateDomainsData();
  };

  const handleModalCreateData = async (e) => {
    e.preventDefault();
    const domainData = {};
    domainData.company_name = currentCurrentCompanyName || '';
    domainData.vertical_name = currentVerticalName || '';
    domainData.possible_domains =
      (currentPossibleDomains && currentPossibleDomains.split(',')) || [];
    domainData.email_type = currentEmailType || '';
    domainData.subject_keywords =
      (currentSubjectKeywords && currentSubjectKeywords.split(',')) || [];

    await createOneDomainData(cookies, domainData);
    await fetchAndUpdateDomainsData();
  };

  const actions = (
    <div className='Actions' onClick={(e) => handleOpenModal(e, 'create', {})}>
      <Plus size={25} />
      New Domain
    </div>
  );

  // Table columns definition
  const columns = [
    {
      name: 'Controls',
      cell: (row) => (
        <div className='RowButtonsContainer'>
          <PencilSquare
            size={20}
            className='RowButton Edit'
            style={{ cursor: 'pointer' }}
            onClick={(e) => handleOpenModal(e, 'edit', row)}
          />
          <Trash
            size={20}
            className='RowButton Delete'
            style={{ cursor: 'pointer' }}
            onClick={() => handleDeleteRow(row)}
          />
        </div>
      ),
      button: true,
    },
    {
      name: 'Company Name',
      selector: (row) => row.company_name,
      sortable: true,
    },
    {
      name: 'Vertical Name',
      selector: (row) => row.vertical_name || '',
      sortable: true,
    },
    {
      name: 'Possible Domains',
      selector: (row) => row.possible_domains,
      sortable: true,
    },
    {
      name: 'Email Type',
      selector: (row) => row.email_type,
      sortable: true,
    },
    {
      name: 'Subject Keywords',
      selector: (row) => (row.subject_keywords && row.subject_keywords.join(' | ')) || '',
      sortable: true,
      grow: 2,
    },
    {
      name: 'Created At',
      selector: (row) => DateTime.fromISO(row.created_at).toFormat('MMM dd, yyyy hh:mm a'),
      sortable: true,
    },
    {
      name: 'Created By',
      selector: (row) => row.created_by,
      sortable: true,
    },
    {
      name: 'Updated At',
      selector: (row) => DateTime.fromISO(row.updated_at).toFormat('MMM dd, yyyy hh:mm a') || '',
      sortable: true,
    },
    {
      name: 'Updated By',
      selector: (row) => row.updated_by || '',
      sortable: true,
    },
  ];

  return (
    <div className='DomainsData'>
      {/* Email Detailed Page Modal */}
      <Modal
        className='EmailModal'
        show={modalShow}
        onHide={() => setModalShow(false)}
        fullscreen={true}
      >
        <Modal.Header className='EmailModalHeader' closeButton>
          <Modal.Title>
            {modalMode === 'edit' ? `Update Domain Data ` : `Create New Domain Data`}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className='EmailModalBody'>
          <div className='ModalMetadata'>
            <Form className='MetadataForm'>
              <Form.Group className='MetadataFormGroup mb-3'>
                <Form.Label className='MetadataFormLabel'>ID</Form.Label>
                <Form.Control type='text' value={currentDomainData.id || ''} disabled />
              </Form.Group>
              <Form.Group className='MetadataFormGroup mb-3'>
                <Form.Label className='MetadataFormLabel'>Company Name</Form.Label>
                <Form.Control
                  type='text'
                  onChange={(e) => setCurrentCurrentCompanyName(e.target.value)}
                  value={currentCurrentCompanyName || ''}
                  required
                />
              </Form.Group>
              <Form.Group className='MetadataFormGroup mb-3'>
                <Form.Label className='MetadataFormLabel'>Vertical Name</Form.Label>
                <Form.Control
                  type='text'
                  onChange={(e) => setCurrentVerticalName(e.target.value)}
                  value={currentVerticalName || ''}
                  required
                />
              </Form.Group>
              <Form.Group className='MetadataFormGroup mb-3'>
                <Form.Label className='MetadataFormLabel'>Possible Domains</Form.Label>
                <Form.Control
                  type='text'
                  onChange={(e) => setCurrentPossibleDomains(e.target.value)}
                  value={currentPossibleDomains || ''}
                  required
                />
              </Form.Group>
              <Form.Group className='MetadataFormGroup mb-3'>
                <Form.Label className='MetadataFormLabel'>Email Type</Form.Label>
                <Form.Control
                  type='text'
                  onChange={(e) => setCurrentEmailType(e.target.value)}
                  value={currentEmailType || ''}
                  required
                />
              </Form.Group>
              <Form.Group className='MetadataFormGroup mb-3'>
                <Form.Label className='MetadataFormLabel'>Subject Keywords</Form.Label>
                <Form.Control
                  type='text'
                  onChange={(e) => setCurrentSubjectKeywords(e.target.value)}
                  value={currentSubjectKeywords || ''}
                  required
                />
              </Form.Group>
              <Form.Group className='MetadataFormGroup mb-3'>
                <Form.Label className='MetadataFormLabel'>Created At</Form.Label>
                <Form.Control type='text' value={currentDomainData.created_at || ''} disabled />
              </Form.Group>
              <Form.Group className='MetadataFormGroup mb-3'>
                <Form.Label className='MetadataFormLabel'>Created By</Form.Label>
                <Form.Control type='text' value={currentDomainData.created_by || ''} disabled />
              </Form.Group>
              <Form.Group className='MetadataFormGroup mb-3'>
                <Form.Label className='MetadataFormLabel'>Updated At</Form.Label>
                <Form.Control type='text' value={currentDomainData.updated_at || ''} disabled />
              </Form.Group>
              <Form.Group className='MetadataFormGroup mb-3'>
                <Form.Label className='MetadataFormLabel'>Updated By</Form.Label>
                <Form.Control type='text' value={currentDomainData.updated_by || ''} disabled />
              </Form.Group>
              {modalMode === 'edit' ? (
                <Button variant='primary' type='submit' onClick={handleModalUpdateData}>
                  Update
                </Button>
              ) : (
                <Button variant='primary' type='submit' onClick={handleModalCreateData}>
                  Create
                </Button>
              )}
            </Form>
          </div>
        </Modal.Body>
      </Modal>

      <DataTable
        className='DataTable'
        title={'Domains Data'}
        columns={columns}
        data={domainsData}
        dense={true}
        striped
        fixedHeader
        fixedHeaderScrollHeight='calc(100% - 56px)'
        progressPending={loading}
        highlightOnHover={true}
        actions={actions}
      />
    </div>
  );
}
