import React from 'react';
import { Navigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';

function PublicRoute({ element }) {
  const [cookies] = useCookies(['access_token']);

  return cookies.access_token ? <Navigate to='/' replace /> : element;
}

export default PublicRoute;
