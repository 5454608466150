import React, { useState, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { DateTime } from 'luxon';
import DataTable from 'react-data-table-component';
import { X, Check2, ArrowUpRightSquare } from 'react-bootstrap-icons'; // eslint-disable-line no-unused-vars
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Table from 'react-bootstrap/Table';
import Dropdown from 'react-bootstrap/Dropdown';
// import { Container, Form, Tab, Tabs } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

// import { Editor } from '@monaco-editor/react';

import { html_beautify } from 'js-beautify';

// import CategoryTable from './CategoryTable';
import {
  getCompanyEmailsData,
  getCompanyEmailsCount,
  // getEmailContent,
  approvePromos,
  rejectPromos,
  // reviewPromos,
  // putCategories,
  // putIndustry,
  putHTML, // eslint-disable-line no-unused-vars
} from './api';
import './MainPane.css';
// import { useNavigate } from 'react-router-dom';

export default function MainPane({
  currentPlatform,
  promotionType,
  setPromotionType,
  countryCode,
  setCountryCode,
  categories,
  setCategories,
  categoriesSchema,
  industries,
  setIndustries,
  industriesSchema,
}) {
  const [cookies] = useCookies(['access_token']);
  const [loading, setLoading] = useState(false);
  const [emailsData, setEmailsData] = useState([]);
  const [clearSelectedRowsToggle, setClearSelectedRowsToggle] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [rowsLimit, setRowsLimit] = useState(20);
  const [totalRows, setTotalRows] = useState(0);
  // const [modalShow, setModalShow] = useState(false);
  const [countModalShow, setCountModalShow] = useState(false);
  const [currentPromoId, setCurrentPromoId] = useState('');
  const [currentEmailContent, setCurrentEmailContent] = useState('');
  const [selectedPromoIds, setSelectedPromoIds] = useState([]);
  const [counts, setCounts] = useState({});

  const [categoryData, setCategoryData] = useState([]);
  // const [categorySearchTerm, setCategorySearchTerm] = useState('');
  const [industryData, setIndustryData] = useState([]);
  // const [industrySearchTerm, setIndustrySearchTerm] = useState('');

  const [editedHTML, setEditedHTML] = useState(currentEmailContent?.html);
  // const [editor, setEditor] = useState();

  // const monacoEditorOptions = {
  //   acceptSuggestionOnCommitCharacter: true,
  //   acceptSuggestionOnEnter: 'on',
  //   accessibilitySupport: 'auto',
  //   autoIndent: true,
  //   automaticLayout: true,
  //   codeLens: true,
  //   colorDecorators: true,
  //   contextmenu: true,
  //   cursorBlinking: 'blink',
  //   cursorSmoothCaretAnimation: false,
  //   cursorStyle: 'line',
  //   disableLayerHinting: false,
  //   disableMonospaceOptimizations: false,
  //   dragAndDrop: false,
  //   fixedOverflowWidgets: false,
  //   folding: true,
  //   foldingStrategy: 'auto',
  //   fontLigatures: false,
  //   formatOnPaste: true,
  //   formatOnType: true,
  //   hideCursorInOverviewRuler: false,
  //   highlightActiveIndentGuide: true,
  //   links: true,
  //   mouseWheelZoom: false,
  //   multiCursorMergeOverlapping: true,
  //   multiCursorModifier: 'alt',
  //   minimap: { enabled: false },
  //   overviewRulerBorder: true,
  //   overviewRulerLanes: 2,
  //   quickSuggestions: true,
  //   quickSuggestionsDelay: 100,
  //   readOnly: false,
  //   renderControlCharacters: false,
  //   renderFinalNewline: true,
  //   renderIndentGuides: true,
  //   renderLineHighlight: 'all',
  //   renderWhitespace: 'none',
  //   revealHorizontalRightPadding: 30,
  //   roundedSelection: true,
  //   rulers: [],
  //   scrollBeyondLastColumn: 5,
  //   scrollBeyondLastLine: false,
  //   selectOnLineNumbers: true,
  //   selectionClipboard: true,
  //   selectionHighlight: true,
  //   showFoldingControls: 'mouseover',
  //   smoothScrolling: false,
  //   suggestOnTriggerCharacters: true,
  //   wordBasedSuggestions: true,
  //   wordSeparators: '~!@#$%^&*()-=+[{]}|;:\'",.<>/?',
  //   wordWrap: 'on',
  //   wordWrapBreakAfterCharacters: '\t})]?|&,;',
  //   wordWrapBreakBeforeCharacters: '{([+',
  //   wordWrapBreakObtrusiveCharacters: '.',
  //   wordWrapColumn: 80,
  //   wordWrapMinified: true,
  //   wrappingIndent: 'none',
  // };

  const jsBeutifyOptions = {
    indent_size: '2',
    indent_char: ' ',
    max_preserve_newlines: '2',
    preserve_newlines: true,
    keep_array_indentation: true,
    break_chained_methods: false,
    indent_scripts: 'normal',
    brace_style: 'collapse,preserve-inline',
    space_before_conditional: true,
    unescape_strings: false,
    jslint_happy: true,
    end_with_newline: true,
    wrap_line_length: '80',
    indent_inner_html: false,
    comma_first: false,
    e4x: false,
    indent_empty_lines: false,
  };

  // const handleEditorOnMount = (editor) => {
  //   setEditor(editor);
  // };

  // const navigate = useNavigate();

  // const handleSave = () => {
  //   console.log('HIT Save !!!');
  //   (async () => {
  //     const currentEmail = emailsData.find((email) => email.promo_id === currentPromoId);
  //     console.log(currentEmail);
  //     console.log(emailsData);
  //     const putHTMLResponse = await putHTML(cookies, currentEmail?.storage_path, editedHTML);

  //     const updateButton = document.querySelector(['#html-update-button']);

  //     if (putHTMLResponse.status === 200) {
  //       updateButton.textContent = 'Updated!';
  //       setTimeout(() => {
  //         updateButton.textContent = 'Save';
  //       }, 2000);
  //     } else {
  //       updateButton.classList.replace('btn-success', 'btn-danger');
  //       updateButton.textContent = 'Error';
  //       setTimeout(() => {
  //         updateButton.classList.replace('btn-danger', 'btn-success');
  //         updateButton.textContent = 'Save';
  //       }, 2000);
  //     }

  //     // const updatedEmailsData = emailsData.filter((emailData) => {
  //     //   return emailData.promo_id !== currentPromoId;
  //     // });
  //     // if (updatedEmailsData.length === 0) {
  //     //   await fetchAndSetEmailData();
  //     // } else {
  //     //   setEmailsData(updatedEmailsData);
  //     // }
  //   })();
  // };

  // const handleEditorChange = (value, event) => {
  //   // console.log('CHANGE', value, event);
  //   // Capture selection using Monaco Editor API
  //   const selection = event.target?.getModel()?.getSelection();
  //   // setSelectedAddressRange({
  //   //   start: selection.startLineNumber,
  //   //   end: selection.endLineNumber,
  //   // });
  //   console.log(selection);
  //   setEditedHTML(value);
  // };

  // const focusAndReplaceTextToHiddent = () => {
  //   if (editor) {
  //     var result = '[HIDDEN]';
  //     var position = editor.getSelection();
  //     editor.executeEdits('', [{ range: position, text: result }]);
  //     editor.focus();
  //   }
  // };

  // const clearAllChanges = () => {
  //   setEditedHTML(html_beautify(currentEmailContent?.html, jsBeutifyOptions));
  // };

  useEffect(() => {
    const formattedHTML = html_beautify(currentEmailContent?.html, jsBeutifyOptions);
    // const formattedHTML = currentEmailContent?.html;
    setEditedHTML(formattedHTML);
  }, [currentEmailContent]);
  // Updates emailData on currentPlatform change
  useEffect(() => {
    if (!currentPlatform) return;
    (async () => {
      await fetchAndSetEmailData();
    })();
  }, [currentPlatform]);

  // Update emailData on promotionType change
  useEffect(() => {
    if (!currentPlatform) return;
    (async () => {
      await fetchAndSetEmailData();
    })();
  }, [promotionType]);

  useEffect(() => {
    if (!currentPlatform) return;
    (async () => {
      await fetchAndSetEmailData();
    })();
  }, [countryCode]);

  useEffect(() => {
    if (!currentPlatform) return;
    (async () => {
      await fetchAndSetEmailData();
    })();
  }, [categories]);

  useEffect(() => {
    if (!currentPlatform) return;
    (async () => {
      await fetchAndSetEmailData();
    })();
  }, [industries]);

  useEffect(() => {
    const selectedPromoIndex = emailsData.findIndex((item) => item.promo_id === currentPromoId);
    console.log(selectedPromoIndex);
    if (selectedPromoIndex !== -1) {
      const categoryFetchData = emailsData[selectedPromoIndex].category_ids;
      const industryFetchData = emailsData[selectedPromoIndex].industry_ids;

      if (!categoryFetchData) {
        setCategoryData([]);
      } else {
        // add hierarchy from the fetched category ids
        const array = categoryFetchData.map((id) => {
          const filter = categoriesSchema?.find((item) => item.id === id);
          // console.log(filter);
          if (!filter) {
            console.log(`Undefined: ${id}`);
          }
          return filter;
        });
        setCategoryData(array);
      }

      if (!industryFetchData) {
        setIndustryData([]);
      } else {
        const industryIdArray = industryFetchData.map((id) => {
          const industryWithName = industriesSchema?.find((item) => item.id === id);
          return industryWithName;
        });
        // const industryWithName = industriesSchema?.find((item) => item.id === industryFetchData);
        // setIndustryData([industryWithName]);
        setIndustryData(industryIdArray);
      }
    }
  }, [currentPromoId]);

  useEffect(() => {
    console.log('Category Data: ', categoryData);
  }, [categoryData]);

  // Function to fetch emailData and set to state
  const fetchAndSetEmailData = async () => {
    setLoading(true);
    setClearSelectedRowsToggle(!clearSelectedRowsToggle);
    const offset = (pageNumber - 1) * rowsLimit;
    const EmailCountResponse = await getCompanyEmailsCount(
      cookies,
      currentPlatform,
      promotionType,
      countryCode,
      categories,
      industries,
    );
    setTotalRows(EmailCountResponse.data.emails_count);
    const emailDataResponse = await getCompanyEmailsData({
      cookies,
      currentCompany: currentPlatform,
      promotionType,
      countryCode,
      category: categories,
      industry: industries,
      offset,
      limit: rowsLimit,
    });
    setEmailsData(emailDataResponse.data.emails_data);
    setLoading(false);
  };

  // // Modal Collection update handler
  // const handleCollectionUpdate = () => {
  //   if (industryData.length <= 0) {
  //     alert('Industry cannot be empty! Try again');
  //     return;
  //   } else if (categoryData.length <= 0) {
  //     alert('Categories cannot be empty! Try again');
  //     return;
  //   }

  //   const formatPutCatrgory = categoryData.map((category) => category.id);
  //   const formatPutIndustry = industryData.map((industry) => industry.id);

  //   (async () => {
  //     // send collections to backend
  //     const putCategoryRes = await putCategories(cookies, currentPromoId, formatPutCatrgory);

  //     // send industries to backend
  //     const putIndustryRes = await putIndustry(cookies, currentPromoId, formatPutIndustry);

  //     const updateButton = document.querySelector(['#collection-update-button']);
  //     // console.log(updateButton);
  //     if (putCategoryRes.status < 400 && putIndustryRes.status < 400) {
  //       updateButton.textContent = 'Updated!';
  //       setTimeout(() => {
  //         updateButton.textContent = 'Set Categories';
  //       }, 2000);
  //     } else {
  //       updateButton.classList.replace('btn-success', 'btn-danger');
  //       updateButton.textContent = 'Error';
  //       setTimeout(() => {
  //         updateButton.classList.replace('btn-danger', 'btn-success');
  //         updateButton.textContent = 'Set Categories';
  //       }, 2000);
  //     }

  //     const updatedEmailsData = emailsData.filter((emailData) => {
  //       return emailData.promo_id !== currentPromoId;
  //     });
  //     if (updatedEmailsData.length === 0) {
  //       await fetchAndSetEmailData();
  //     } else {
  //       setEmailsData(updatedEmailsData);
  //     }
  //   })();
  // };

  // // Single Row Full Info handler
  // const handleRowInfo = (row) => {
  //   (async () => {
  //     setCurrentEmailContent('');
  //     setCurrentPromoId(row.promo_id);
  //     setModalShow(true);
  //     const response = await getEmailContent(cookies, row.storage_path);
  //     const emailData = response.data.email_data;
  //     setCurrentEmailContent(emailData);
  //   })();
  // };

  const handleCountInfo = () => {
    (async () => {
      setCountModalShow(true);
      const approveResponse = await getCompanyEmailsCount(cookies, '', 'approved', '', '', '');
      const rejectResponse = await getCompanyEmailsCount(cookies, '', 'rejected', '', '', '');
      const pendingResponse = await getCompanyEmailsCount(cookies, '', 'pending', '', '', '');
      const reviewResponse = await getCompanyEmailsCount(cookies, '', 'review', '', '', '');

      const structuredCount = {
        approveCount: approveResponse.data.emails_count,
        rejectCount: rejectResponse.data.emails_count,
        pendingCount: pendingResponse.data.emails_count,
        reviewCount: reviewResponse.data.emails_count,
      };

      setCounts(structuredCount);

      // console.log(counts);
      console.log(
        typeof setCurrentPromoId,
        typeof setCurrentEmailContent,
        industryData,
        editedHTML,
      );
    })();
  };

  // // Email Modal Approve handler
  // const handleModalApprove = async (e) => {
  //   e.preventDefault();
  //   const promoIds = [currentPromoId];
  //   await approvePromos(cookies, promoIds);
  //   const updatedEmailsData = emailsData.filter((emailData) => {
  //     return emailData.promo_id !== currentPromoId;
  //   });
  //   if (updatedEmailsData.length === 0) {
  //     await fetchAndSetEmailData();
  //   } else {
  //     setEmailsData(updatedEmailsData);
  //   }
  //   setModalShow(false);
  // };

  // // Email Modal Reject handler
  // const handleModalReject = async (e) => {
  //   e.preventDefault();
  //   const promoIds = [currentPromoId];
  //   await rejectPromos(cookies, promoIds);
  //   const updatedEmailsData = emailsData.filter((emailData) => {
  //     return emailData.promo_id !== currentPromoId;
  //   });
  //   if (updatedEmailsData.length === 0) {
  //     await fetchAndSetEmailData();
  //   } else {
  //     setEmailsData(updatedEmailsData);
  //   }
  //   setModalShow(false);
  // };
  // // Email Modal Review Handler
  // const handleModalReview = async (e) => {
  //   e.preventDefault();
  //   const promoIds = [currentPromoId];
  //   await reviewPromos(cookies, promoIds);
  //   const updatedEmailsData = emailsData.filter((emailData) => {
  //     return emailData.promo_id !== currentPromoId;
  //   });
  //   if (updatedEmailsData.length === 0) {
  //     await fetchAndSetEmailData();
  //   } else {
  //     setEmailsData(updatedEmailsData);
  //   }
  //   setModalShow(false);
  // };

  // Pagination - Row limit change handler
  const handleRowsLimitChange = async (rows) => {
    if (!currentPlatform) return;
    setLoading(true);
    setRowsLimit(rows);
    const offset = (pageNumber - 1) * rows;
    const response = await getCompanyEmailsData({
      cookies,
      currentCompany: currentPlatform,
      limit: rows,
      offset,
      countryCode,
      promotionType,
      category: categories,
      industry: industries,
    });
    setEmailsData(response.data.emails_data);
    setLoading(false);
  };

  // Pagination - Page change handler
  const handlePageChange = async (page) => {
    if (!currentPlatform) return;
    setLoading(true);
    setPageNumber(page);
    const offset = (page - 1) * rowsLimit;
    const response = await getCompanyEmailsData({
      cookies,
      currentCompany: currentPlatform,
      promotionType,
      countryCode,
      category: categories,
      industry: industries,
      offset,
      limit: rowsLimit,
    });
    setEmailsData(response.data.emails_data);
    setLoading(false);
  };

  // Row Selection handler
  const handleRowSelection = (state) => {
    const promoIds = state.selectedRows.map((row) => row.promo_id);
    setSelectedPromoIds(promoIds);
  };

  // Selection Approve all handler
  const handleApproveAll = async (e) => {
    e.preventDefault();
    await approvePromos(cookies, selectedPromoIds);
    const updatedEmailsData = emailsData.filter((emailData) => {
      return !selectedPromoIds.includes(emailData.promo_id);
    });
    if (updatedEmailsData.length === 0) {
      await fetchAndSetEmailData();
    } else {
      setEmailsData(updatedEmailsData);
    }
    setClearSelectedRowsToggle(!clearSelectedRowsToggle);
  };

  // Selection Reject all handler
  const handleRejectAll = async (e) => {
    e.preventDefault();
    await rejectPromos(cookies, selectedPromoIds);
    const updatedEmailsData = emailsData.filter((emailData) => {
      return !selectedPromoIds.includes(emailData.promo_id);
    });
    if (updatedEmailsData.length === 0) {
      await fetchAndSetEmailData();
    } else {
      setEmailsData(updatedEmailsData);
    }
    setClearSelectedRowsToggle(!clearSelectedRowsToggle);
  };

  // Selection Approve/Reject buttons component
  const selectControlButtons = (
    <div>
      <Button className='SelectControlButtons' variant='success' onClick={handleApproveAll}>
        Approve All
      </Button>
      <Button className='SelectControlButtons' variant='danger' onClick={handleRejectAll}>
        Reject All
      </Button>
    </div>
  );

  const dropdownButtons = (
    <div className='menubar'>
      <div>
        Counts
        <ArrowUpRightSquare
          size={25}
          className='RowButton Open'
          onClick={() => handleCountInfo()}
        />
      </div>
      <Dropdown className='Dropdown'>
        <Dropdown.Toggle variant='Secondary' id='dropdown-basic' size='sm'>
          {countryCode}
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <Dropdown.Item title='Pending' defaultChecked={true} onClick={() => setCountryCode('US')}>
            US
          </Dropdown.Item>
          <Dropdown.Item title='Approved' onClick={() => setCountryCode('IN')}>
            IN
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>

      <Dropdown className='Dropdown'>
        <Dropdown.Toggle variant='Secondary' id='dropdown-basic' size='sm'>
          Categories
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <Dropdown.Item
            title='No Filter'
            onClick={() => {
              setCategories('');
            }}
          >
            No Filter
          </Dropdown.Item>
          {categoriesSchema.map((category) => {
            return (
              <Dropdown.Item
                key={category.id}
                className={category.hierarchy === categories ? 'SelectedItem' : ''}
                title={category.hierarchy}
                onClick={() => {
                  setCategories(category.id);
                }}
              >
                {category.hierarchy}
              </Dropdown.Item>
            );
          })}
        </Dropdown.Menu>
      </Dropdown>

      <Dropdown className='Dropdown'>
        <Dropdown.Toggle variant='Secondary' id='dropdown-basic' size='sm'>
          Industry
        </Dropdown.Toggle>

        <Dropdown.Menu style={{ maxHeight: '400px', overflowY: 'scroll' }}>
          <Dropdown.Item
            title='No Filter'
            onClick={() => {
              setIndustries('');
            }}
          >
            No Filter
          </Dropdown.Item>
          {industriesSchema.map((industry) => {
            return (
              <Dropdown.Item
                key={industry.id}
                className={industry.hierarchy === industries ? 'SelectedItem' : ''}
                title={industry.hierarchy}
                onClick={() => {
                  setIndustries(industry.id);
                }}
              >
                {industry.hierarchy}
              </Dropdown.Item>
            );
          })}
        </Dropdown.Menu>
      </Dropdown>

      <Dropdown className='Dropdown'>
        <Dropdown.Toggle variant='Secondary' id='dropdown-basic' size='sm'>
          {promotionType}
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <Dropdown.Item
            title='Pending'
            defaultChecked={true}
            onClick={() => setPromotionType('pending')}
          >
            Pending Emails
          </Dropdown.Item>
          <Dropdown.Item title='Approved' onClick={() => setPromotionType('approved')}>
            Approved Emails
          </Dropdown.Item>
          <Dropdown.Item title='Rejected' onClick={() => setPromotionType('rejected')}>
            Rejected Emails
          </Dropdown.Item>
          <Dropdown.Item title='Review' onClick={() => setPromotionType('review')}>
            Marked for Review
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );

  const handleOpenModalNewTab = (row) => {
    // const currentPromoId = /* your current promo id */;
    // const currentPlatform = /* your current platform */;
    // const promotionType = /* your promotion type */;
    // const countryCode = /* your country code */;
    // const categories = /* your categories */;
    // const setPromotionType = /* your setPromotionType function */;
    // const setCountryCode = /* your setCountryCode function */;
    // const setCategories = /* your setCategories function */;
    // const categoriesSchema = /* your categories schema */;
    // const industries = /* your industries */;
    // const setIndustries = /* your setIndustries function */;
    // const industriesSchema = /* your industries schema */;

    const url =
      `/mailsights-admin/${row.promo_id}?` +
      `promo_id=${encodeURIComponent(row.promo_id)}` +
      `&storage_path=${encodeURIComponent(row.storage_path)}` +
      `&currentPlatform=${encodeURIComponent(currentPlatform)}` +
      // `&promotionType=${encodeURIComponent(promotionType)}` +
      // `&setPromotionType=${encodeURIComponent(setPromotionType)}` +
      // `&countryCode=${encodeURIComponent(countryCode)}` +
      // `&setCountryCode=${encodeURIComponent(setCountryCode)}` +
      `&categories=${encodeURIComponent(row.category_ids)}` +
      // `&setCategories=${encodeURIComponent(setCategories)}` +
      // `&categoriesSchema=${encodeURIComponent(categoriesSchema)}` +
      `&industries=${encodeURIComponent(row.industry_ids)}`;
    // `&setIndustries=${encodeURIComponent(setIndustries)}` +
    // `&industriesSchema=${encodeURIComponent(industriesSchema)}`
    // Open the link in a new tab with _blank
    const newWindow = window.open(url, '_blank', 'noopener noreferrer');

    // Attach props to the new window's window object
    if (newWindow) {
      newWindow.currentPlatform = currentPlatform;
      newWindow.promotionType = promotionType;
      newWindow.setPromotionType = setPromotionType;
      newWindow.countryCode = countryCode;
      newWindow.setCountryCode = setCountryCode;
      newWindow.categories = categories;
      newWindow.setCategories = setCategories;
      newWindow.categoriesSchema = categoriesSchema;
      newWindow.industries = industries;
      newWindow.setIndustries = setIndustries;
      newWindow.industriesSchema = industriesSchema;
    }

    // Pass state to the new tab using localStorage or sessionStorage
    sessionStorage.setItem(
      'stateForNewTab',
      JSON.stringify({
        currentPlatform,
        promotionType,
        setPromotionType,
        countryCode,
        setCountryCode,
        categories,
        setCategories,
        categoriesSchema,
        industries,
        setIndustries,
        industriesSchema,
      }),
    );
  };

  // Table columns definition
  const columns = [
    {
      name: 'Sender Email',
      selector: (row) => row.sender_email,
      grow: 1,
    },
    {
      name: 'Subject',
      selector: (row) => row.subject,
      grow: 2,
    },
    {
      cell: (row) => (
        <div className='RowButtonsContainer'>
          <ArrowUpRightSquare
            size={25}
            className='RowButton Open'
            onClick={() => handleOpenModalNewTab(row)}
          />
        </div>
      ),
      button: true,
    },
    {
      name: 'Timestamp',
      selector: (row) => DateTime.fromISO(row.sent_timestamp).toFormat('MMM dd, yyyy hh:mm a'),
      sortable: true,
      grow: 0.7,
    },
    {
      name: 'Count',
      selector: (row) => row.overall_count,
      sortable: true,
      grow: 0,
    },
    {
      name: 'Categories',
      selector: (row) => (row.category_ids ? row.category_ids.length : 0),
      sortable: true,
      grow: 0.4,
    },
    {
      name: 'Industry',
      selector: (row) => (row.industry_ids ? row.industry_ids.length : 0),
      sortable: true,
      grow: 0.5,
    },
  ];

  // const handleCategoryChange = (categoryId) => {
  //   const category = categoriesSchema.find((item) => item.id === categoryId);

  //   if (!category) {
  //     // Handle invalid category selection
  //     return;
  //   }

  //   setCategoryData((prevCategoryData) => {
  //     const updatedCategoryData = [...prevCategoryData];

  //     const categoryIndex = updatedCategoryData.findIndex((item) => item.id === categoryId);

  //     if (categoryIndex !== -1) {
  //       // Category exists, remove it
  //       updatedCategoryData.splice(categoryIndex, 1);
  //     } else {
  //       // Category doesn't exist, create it with an empty sub_collections array
  //       updatedCategoryData.push({
  //         id: category.id,
  //         hierarchy: category.hierarchy,
  //       });
  //     }
  //     return updatedCategoryData;
  //   });
  // };

  // const handleIndustryChange = (industry) => {
  //   const newIndustry = industriesSchema.find((item) => item.id === industry.id);

  //   if (!newIndustry) {
  //     // Handle invalid industry selection
  //     return;
  //   }

  //   // console.log(industry);

  //   setIndustryData((prevIndustry) => {
  //     let updatedIndustryData = [...prevIndustry];

  //     // If exists, remove

  //     const exists = updatedIndustryData.find((item) => item.id === industry.id);

  //     if (exists) {
  //       updatedIndustryData = updatedIndustryData.filter((item) => item.id !== industry.id);
  //     } else {
  //       // if not exist add
  //       updatedIndustryData.push({
  //         id: newIndustry.id,
  //         hierarchy: newIndustry.hierarchy,
  //       });
  //     }
  //     return updatedIndustryData;
  //   });
  // };

  // MainPane component
  return (
    <div className='MainPane'>
      {/* Email Detailed Page Modal */}

      {/* Counts Modal */}
      <Modal
        className='EmailModal'
        show={countModalShow}
        onHide={() => setCountModalShow(false)}
        fullscreen={true}
      >
        <Modal.Header className='EmailModalHeader' closeButton>
          <Modal.Title>Email Count</Modal.Title>
        </Modal.Header>
        <div className='ModalContainer'>
          {Object.keys(counts).length !== 0 ? (
            <Table striped bordered hover size='sm'>
              <tbody>
                <tr>
                  <td>Total Approved Counts</td>
                  <td>{counts.approveCount}</td>
                </tr>
                <tr>
                  <td>Total Rejected Counts</td>
                  <td>{counts.rejectCount}</td>
                </tr>
                <tr>
                  <td>Total Pending Counts</td>
                  <td>{counts.pendingCount}</td>
                </tr>
                <tr>
                  <td>Total Review Counts</td>
                  <td>{counts.reviewCount}</td>
                </tr>
              </tbody>
            </Table>
          ) : (
            <p>Loading total counts ... </p>
          )}
        </div>
      </Modal>

      {/* Email Metadata Table */}
      <DataTable
        className='DataTable'
        title={'Emails - ' + currentPlatform}
        columns={columns}
        data={emailsData}
        dense
        striped
        fixedHeader
        fixedHeaderScrollHeight='calc(100% - 112px)'
        progressPending={loading}
        selectableRows
        onSelectedRowsChange={handleRowSelection}
        clearSelectedRows={clearSelectedRowsToggle}
        selectableRowsHighlight={true}
        highlightOnHover={true}
        pagination
        paginationServer
        paginationTotalRows={totalRows}
        paginationPerPage={rowsLimit}
        paginationRowsPerPageOptions={[20, 40, 60, 80, 100]}
        onChangeRowsPerPage={handleRowsLimitChange}
        onChangePage={handlePageChange}
        actions={dropdownButtons}
        contextActions={selectControlButtons}
      />
    </div>
  );
}
