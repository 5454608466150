import React, { useEffect } from 'react';
import SidePaneBrowser from './RenderMasterSidePaneBrowser';
import SidePaneSearch from './RenderMasterSidePaneSearch';
import { getDirsAndFiles, getFileContent } from './api';
import './RenderMasterSidePane.css';

export default function RenderMasterSidePane({
  cookie,
  breadcrumb,
  setBreadcrumb,
  dirs,
  setDirs,
  files,
  setFiles,
  fileName,
  setFileName,
  setFileContent,
  setCurrentPage,
  nextPageToken,
  setNextPage,
  isLoading,
  setIsLoading,
}) {
  // Update dirs and files on state when breadcrumb changes
  useEffect(() => {
    (async () => {
      setIsLoading(true);
      let prefix = breadcrumb.join('/');
      prefix = prefix === '' ? prefix : prefix.concat('/');
      const response = await getDirsAndFiles(cookie, prefix);

      if (response.data && response.data.nextPageToken) {
        setCurrentPage(nextPageToken);
        setNextPage(response.data.nextPageToken);
      }

      // Set dirs on state if present in response
      if (response.data.prefixes) {
        const dirs = response.data.prefixes.reduce((dirs, prefix) => {
          dirs.push(prefix.split('/')[prefix.split('/').length - 2]);
          return dirs;
        }, []);
        setDirs(dirs);
      } else {
        setDirs([]);
      }

      // Set files on state if present in response
      if (response.data.items) {
        const files = response.data.items.reduce((files, item) => {
          const path = item.name;
          const fileName = path.split('/')[path.split('/').length - 1];
          files.push(fileName);
          return files;
        }, []);
        setFiles(files);
      } else {
        setFiles([]);
      }
      setIsLoading(false);
    })();
  }, [breadcrumb]);

  // Set the file Content on state if fileName is present for RenderMasterMainPane to render
  useEffect(() => {
    if (fileName) {
      const prefix = breadcrumb.join('/');
      const filePath = encodeURIComponent(`${prefix}/${fileName}`);
      (async () => {
        setIsLoading(true);
        const response = await getFileContent(cookie, filePath);
        setFileContent(response.data);
        setIsLoading(false);
      })();
    }
  }, [fileName]);

  // Search for files and dirs by prefix
  async function searchDirAndFiles(searchTerm) {
    setIsLoading(true);
    let fileKeyword = '';
    let prefix = breadcrumb.join('/');
    prefix = prefix === '' ? prefix : prefix.concat('/');
    if (prefix.split('/').length > 4) {
      fileKeyword = searchTerm;
    } else {
      prefix = prefix.concat(searchTerm);
    }
    const response = await getDirsAndFiles(cookie, prefix, '', fileKeyword);

    if (response.data && response.data.nextPageToken) {
      setCurrentPage(nextPageToken);
      setNextPage(response.data.nextPageToken);
    }

    // Set dirs on state if present in response
    if (response.data.prefixes) {
      const dirs = response.data.prefixes.reduce((dirs, prefix) => {
        dirs.push(prefix.split('/')[prefix.split('/').length - 2]);
        return dirs;
      }, []);
      setDirs(dirs);
    } else {
      setDirs([]);
    }

    // Set files on state if present in response
    if (response.data.items) {
      const files = response.data.items.reduce((files, item) => {
        const path = item.name;
        const fileName = path.split('/')[path.split('/').length - 1];
        files.push(fileName);
        return files;
      }, []);
      setFiles(files);
    } else {
      setFiles([]);
    }
    setIsLoading(false);
  }

  return (
    <div className='RenderMasterSidePane'>
      <SidePaneSearch searchDirAndFiles={searchDirAndFiles} />
      <SidePaneBrowser
        breadcrumb={breadcrumb}
        setBreadcrumb={setBreadcrumb}
        dirs={dirs}
        files={files}
        setFileName={setFileName}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
      />
    </div>
  );
}
