import React, { useEffect, useState } from 'react';
import './MainPane.css';
import './MailsightsAdmin.css';
import './SidePane.css';
import './SearchBar.css';
import { Button, Container, Dropdown, Form, Modal, Tab, Tabs, Table } from 'react-bootstrap';
import { Editor } from '@monaco-editor/react';
import CategoryTable from './CategoryTable';
import { useCookies } from 'react-cookie';
import {
  approvePromos,
  getCollectionSchema,
  //   getCompanyEmailsCount,
  //   getCompanyEmailsData,
  getEmailContent,
  getIndustriesSchema,
  putCategories,
  putHTML,
  putIndustry,
  rejectPromos,
  reviewPromos,
} from './api';
import { html_beautify } from 'js-beautify';
import { useLocation } from 'react-router-dom';

const DataModal = () => {
  const location = useLocation();

  // Declare variables with file-wide scope
  let promo_id;
  let storage_path;
  let currentPlatform;
  //   let promotionType;
  //   let setPromotionType;
  //   let countryCode;
  //   let setCountryCode;
  let categories;
  //   let setCategories;
  //   let categoriesSchema;
  let industries;
  //   let setIndustries;
  //   let industriesSchema;

  useEffect(() => {
    // Parse query parameters from the URL
    const queryParams = new URLSearchParams(location.search);

    // Access individual query parameters
    promo_id = queryParams.get('promo_id');
    storage_path = decodeURIComponent(queryParams.get('storage_path'));
    currentPlatform = queryParams.get('currentPlatform');
    // promotionType = queryParams.get('promotionType');
    // setPromotionType = queryParams.get('setPromotionType');
    // countryCode = queryParams.get('countryCode');
    // setCountryCode = queryParams.get('setCountryCode');
    categories = decodeURIComponent(queryParams.get('categories'));
    // setCategories = queryParams.get('setCategories');
    // categoriesSchema = queryParams.get('categoriesSchema');
    industries = decodeURIComponent(queryParams.get('industries'));
    // setIndustries = queryParams.get('setIndustries');
    // industriesSchema = queryParams.get('industriesSchema');

    // Use the values as needed in your component logic
  }, [location.search]);

  const [cookies] = useCookies(['access_token']);
  //   const [loading, setLoading] = useState(false);
  const [emailsData, setEmailsData] = useState([]);
  //   const [clearSelectedRowsToggle, setClearSelectedRowsToggle] = useState(false);
  //   const [pageNumber, setPageNumber] = useState(1);
  //   const [rowsLimit, setRowsLimit] = useState(20);
  //   const [totalRows, setTotalRows] = useState(0);
  //   //   const [modalShow, setModalShow] = useState(false);
  //   //   const [countModalShow, setCountModalShow] = useState(false);
  //   const [currentPromoId, setCurrentPromoId] = useState('');
  const [currentEmailContent, setCurrentEmailContent] = useState('');
  //   //   const [selectedPromoIds, setSelectedPromoIds] = useState([]);
  //   //   const [counts, setCounts] = useState({});

  const [categoryData, setCategoryData] = useState([]);
  const [categorySearchTerm, setCategorySearchTerm] = useState('');
  const [industryData, setIndustryData] = useState([]);
  const [industrySearchTerm, setIndustrySearchTerm] = useState('');
  const [categoriesSchema, setCategoriesSchema] = useState([]);
  const [industriesSchema, setIndustriesSchema] = useState([]);

  const [editedHTML, setEditedHTML] = useState(currentEmailContent?.html);
  const [editor, setEditor] = useState();

  const monacoEditorOptions = {
    acceptSuggestionOnCommitCharacter: true,
    acceptSuggestionOnEnter: 'on',
    accessibilitySupport: 'auto',
    autoIndent: true,
    automaticLayout: true,
    codeLens: true,
    colorDecorators: true,
    contextmenu: true,
    cursorBlinking: 'blink',
    cursorSmoothCaretAnimation: false,
    cursorStyle: 'line',
    disableLayerHinting: false,
    disableMonospaceOptimizations: false,
    dragAndDrop: false,
    fixedOverflowWidgets: false,
    folding: true,
    foldingStrategy: 'auto',
    fontLigatures: false,
    formatOnPaste: true,
    formatOnType: true,
    hideCursorInOverviewRuler: false,
    highlightActiveIndentGuide: true,
    links: true,
    mouseWheelZoom: false,
    multiCursorMergeOverlapping: true,
    multiCursorModifier: 'alt',
    minimap: { enabled: false },
    overviewRulerBorder: true,
    overviewRulerLanes: 2,
    quickSuggestions: true,
    quickSuggestionsDelay: 100,
    readOnly: false,
    renderControlCharacters: false,
    renderFinalNewline: true,
    renderIndentGuides: true,
    renderLineHighlight: 'all',
    renderWhitespace: 'none',
    revealHorizontalRightPadding: 30,
    roundedSelection: true,
    rulers: [],
    scrollBeyondLastColumn: 5,
    scrollBeyondLastLine: false,
    selectOnLineNumbers: true,
    selectionClipboard: true,
    selectionHighlight: true,
    showFoldingControls: 'mouseover',
    smoothScrolling: false,
    suggestOnTriggerCharacters: true,
    wordBasedSuggestions: true,
    wordSeparators: '~!@#$%^&*()-=+[{]}|;:\'",.<>/?',
    wordWrap: 'on',
    wordWrapBreakAfterCharacters: '\t})]?|&,;',
    wordWrapBreakBeforeCharacters: '{([+',
    wordWrapBreakObtrusiveCharacters: '.',
    wordWrapColumn: 80,
    wordWrapMinified: true,
    wrappingIndent: 'none',
  };

  const jsBeutifyOptions = {
    indent_size: '2',
    indent_char: ' ',
    max_preserve_newlines: '2',
    preserve_newlines: true,
    keep_array_indentation: true,
    break_chained_methods: false,
    indent_scripts: 'normal',
    brace_style: 'collapse,preserve-inline',
    space_before_conditional: true,
    unescape_strings: false,
    jslint_happy: true,
    end_with_newline: true,
    wrap_line_length: '80',
    indent_inner_html: false,
    comma_first: false,
    e4x: false,
    indent_empty_lines: false,
  };

  useEffect(() => {
    // console.log(resultArray);

    setCategoryData((prev) => {
      // Function to convert an ID to its corresponding hierarchy object
      console.log(prev);

      const convertIdToHierarchy = (id) => {
        const category = categoriesSchema.find((category) => category.id === id);
        return category
          ? {
              id: category.id,
              hierarchy: category.hierarchy,
            }
          : null;
      };

      // Function to convert an array of IDs to an array of hierarchy objects
      const convertArrayToHierarchy = (idArray) => {
        return idArray.map((id) => convertIdToHierarchy(id)).filter(Boolean);
      };

      // Convert the input array to the desired format
      const resultArray = convertArrayToHierarchy(categories.split(','));

      return resultArray;
    });
    setIndustryData((prev) => {
      // Function to convert an ID to its corresponding hierarchy object
      console.log(prev);
      const convertIdToHierarchy = (id) => {
        const industry = industriesSchema.find((industry) => industry.id === id);
        return industry
          ? {
              id: industry.id,
              hierarchy: industry.hierarchy,
            }
          : null;
      };

      // Function to convert an array of IDs to an array of hierarchy objects
      const convertArrayToHierarchy = (idArray) => {
        return idArray.map((id) => convertIdToHierarchy(id)).filter(Boolean);
      };

      // Convert the input array to the desired format
      const resultArray = convertArrayToHierarchy(industries.split(','));

      return resultArray;
    });
  }, [categories, industries]);

  useEffect(() => {
    console.log(`Cat: ${categoryData}\nInd: ${industryData}`);
    console.log(categoriesSchema);
  }, [categoryData, industryData]);

  //   const location = useLocation();

  //   const queryParams = new URLSearchParams(location.search);

  //   // Access individual query parameters
  //   const promo_id = queryParams.get('promo_id');
  //   const storage_path = queryParams.get('storage_path');

  const handleEditorOnMount = (editor) => {
    setEditor(editor);
  };

  const handleSave = () => {
    console.log('HIT Save !!!');
    (async () => {
      const currentEmail = emailsData.find((email) => email.promo_id === promo_id);
      console.log(currentEmail);
      console.log(emailsData);
      const putHTMLResponse = await putHTML(cookies, currentEmail?.storage_path, editedHTML);

      const updateButton = document.querySelector(['#html-update-button']);

      if (putHTMLResponse.status === 200) {
        updateButton.textContent = 'Updated!';
        setTimeout(() => {
          updateButton.textContent = 'Save';
        }, 2000);
      } else {
        updateButton.classList.replace('btn-success', 'btn-danger');
        updateButton.textContent = 'Error';
        setTimeout(() => {
          updateButton.classList.replace('btn-danger', 'btn-success');
          updateButton.textContent = 'Save';
        }, 2000);
      }

      // const updatedEmailsData = emailsData.filter((emailData) => {
      //   return emailData.promo_id !== currentPromoId;
      // });
      // if (updatedEmailsData.length === 0) {
      //   await fetchAndSetEmailData();
      // } else {
      //   setEmailsData(updatedEmailsData);
      // }
    })();
  };

  const handleEditorChange = (value, event) => {
    // console.log('CHANGE', value, event);
    // Capture selection using Monaco Editor API
    const selection = event.target?.getModel()?.getSelection();
    // setSelectedAddressRange({
    //   start: selection.startLineNumber,
    //   end: selection.endLineNumber,
    // });
    console.log(selection);
    setEditedHTML(value);
  };

  const focusAndReplaceTextToHiddent = () => {
    if (editor) {
      var result = '[HIDDEN]';
      var position = editor.getSelection();
      editor.executeEdits('', [{ range: position, text: result }]);
      editor.focus();
    }
  };

  const clearAllChanges = () => {
    setEditedHTML(html_beautify(currentEmailContent?.html, jsBeutifyOptions));
    // console.log(loading, totalRows, typeof setPageNumber, typeof setRowsLimit);
  };

  useEffect(() => {
    async function fetchCollectionSchema() {
      const response = await getCollectionSchema(cookies);
      const categories = response.data.categories;

      // const analyticsDataIds = [
      //   'f0ea070b-b59c-4786-8fa9-105d8b6d1331',
      //   'ea19a6ee-7e3e-4665-a4b4-7b0bf2b56e48',
      //   '5a55c7bb-5d98-48ee-9f76-633650e2e5fe',
      //   '65ecec91-4b6b-4a29-8733-723395410ec6',
      //   'a227bcda-5a3c-4466-b85f-f28c3d5746e4',
      // ];

      // const categoriesWihtoutAnalytics = categories.filter(
      //   (category) => !analyticsDataIds.includes(category.id),
      // );
      // console.log(categoriesWihtoutAnalytics);

      setCategoriesSchema(categories);
      // setCategoriesSchema(categoriesWihtoutAnalytics);
    }
    fetchCollectionSchema();
  }, []);

  useEffect(() => {
    async function fetchIndustriesSchema() {
      const response = await getIndustriesSchema(cookies);
      const industries = response.data.industries;
      setIndustriesSchema(industries);
    }
    fetchIndustriesSchema();
  }, []);

  useEffect(() => {
    (async () => {
      setCurrentEmailContent('');
      //   setCurrentPromoId(promo_id);
      //   setModalShow(true);
      const response = await getEmailContent(cookies, storage_path);
      const emailData = response.data.email_data;
      setCurrentEmailContent(emailData);
    })();
  }, []);

  useEffect(() => {
    const formattedHTML = html_beautify(currentEmailContent?.html, jsBeutifyOptions);
    // const formattedHTML = currentEmailContent?.html;
    setEditedHTML(formattedHTML);
  }, [currentEmailContent]);
  // Updates emailData on currentPlatform change
  //   useEffect(() => {
  //     if (!currentPlatform) return;
  //     (async () => {
  //       await fetchAndSetEmailData();
  //     })();
  //   }, [currentPlatform]);

  //   // Update emailData on promotionType change
  //   useEffect(() => {
  //     if (!currentPlatform) return;
  //     (async () => {
  //       await fetchAndSetEmailData();
  //     })();
  //   }, [promotionType]);

  //   useEffect(() => {
  //     if (!currentPlatform) return;
  //     (async () => {
  //       await fetchAndSetEmailData();
  //     })();
  //   }, [countryCode]);

  //   useEffect(() => {
  //     if (!currentPlatform) return;
  //     (async () => {
  //       await fetchAndSetEmailData();
  //     })();
  //   }, [categories]);

  //   useEffect(() => {
  //     if (!currentPlatform) return;
  //     (async () => {
  //       await fetchAndSetEmailData();
  //     })();
  //   }, [industries]);

  useEffect(() => {
    const selectedPromoIndex = emailsData.findIndex((item) => item.promo_id === promo_id);
    console.log(selectedPromoIndex);
    if (selectedPromoIndex !== -1) {
      const categoryFetchData = emailsData[selectedPromoIndex].category_ids;
      const industryFetchData = emailsData[selectedPromoIndex].industry_ids;

      if (!categoryFetchData) {
        setCategoryData([]);
      } else {
        // add hierarchy from the fetched category ids
        const array = categoryFetchData.map((id) => {
          const filter = categoriesSchema?.find((item) => item.id === id);
          // console.log(filter);
          if (!filter) {
            console.log(`Undefined: ${id}`);
          }
          return filter;
        });
        setCategoryData(array);
      }

      if (!industryFetchData) {
        setIndustryData([]);
      } else {
        const industryIdArray = industryFetchData.map((id) => {
          const industryWithName = industriesSchema?.find((item) => item.id === id);
          return industryWithName;
        });
        // const industryWithName = industriesSchema?.find((item) => item.id === industryFetchData);
        // setIndustryData([industryWithName]);
        setIndustryData(industryIdArray);
      }
    }
  }, [promo_id]);

  useEffect(() => {
    console.log('Category Data: ', categoryData);
  }, [categoryData]);

  //   // Function to fetch emailData and set to state
  //   const fetchAndSetEmailData = async () => {
  //     setLoading(true);
  //     setClearSelectedRowsToggle(!clearSelectedRowsToggle);
  //     const offset = (pageNumber - 1) * rowsLimit;
  //     const EmailCountResponse = await getCompanyEmailsCount(
  //       cookies,
  //       currentPlatform,
  //       promotionType,
  //       countryCode,
  //       categories,
  //       industries,
  //     );
  //     setTotalRows(EmailCountResponse.data.emails_count);
  //     const emailDataResponse = await getCompanyEmailsData({
  //       cookies,
  //       currentCompany: currentPlatform,
  //       promotionType,
  //       countryCode,
  //       category: categories,
  //       industry: industries,
  //       offset,
  //       limit: rowsLimit,
  //     });
  //     setEmailsData(emailDataResponse.data.emails_data);
  //     setLoading(false);
  //   };

  // Modal Collection update handler
  const handleCollectionUpdate = () => {
    if (industryData.length <= 0) {
      alert('Industry cannot be empty! Try again');
      return;
    } else if (categoryData.length <= 0) {
      alert('Categories cannot be empty! Try again');
      return;
    }

    const formatPutCatrgory = categoryData.map((category) => category.id);
    const formatPutIndustry = industryData.map((industry) => industry.id);

    (async () => {
      // send collections to backend
      const putCategoryRes = await putCategories(cookies, promo_id, formatPutCatrgory);

      // send industries to backend
      const putIndustryRes = await putIndustry(cookies, promo_id, formatPutIndustry);

      const updateButton = document.querySelector(['#collection-update-button']);
      // console.log(updateButton);
      if (putCategoryRes.status < 400 && putIndustryRes.status < 400) {
        updateButton.textContent = 'Updated!';
        setTimeout(() => {
          updateButton.textContent = 'Set Categories';
        }, 2000);
      } else {
        updateButton.classList.replace('btn-success', 'btn-danger');
        updateButton.textContent = 'Error';
        setTimeout(() => {
          updateButton.classList.replace('btn-danger', 'btn-success');
          updateButton.textContent = 'Set Categories';
        }, 2000);
      }

      const updatedEmailsData = emailsData.filter((emailData) => {
        return emailData.promo_id !== promo_id;
      });
      if (updatedEmailsData.length === 0) {
        // await fetchAndSetEmailData();
      } else {
        setEmailsData(updatedEmailsData);
      }
    })();
  };

  //   // Single Row Full Info handler
  //   const handleRowInfo = (row) => {
  //     (async () => {
  //       setCurrentEmailContent('');
  //       setCurrentPromoId(row.promo_id);
  //       setModalShow(true);
  //       const response = await getEmailContent(cookies, row.storage_path);
  //       const emailData = response.data.email_data;
  //       setCurrentEmailContent(emailData);
  //     })();
  //   };

  //   const handleCountInfo = () => {
  //     (async () => {
  //       setCountModalShow(true);
  //       const approveResponse = await getCompanyEmailsCount(cookies, '', 'approved', '', '', '');
  //       const rejectResponse = await getCompanyEmailsCount(cookies, '', 'rejected', '', '', '');
  //       const pendingResponse = await getCompanyEmailsCount(cookies, '', 'pending', '', '', '');
  //       const reviewResponse = await getCompanyEmailsCount(cookies, '', 'review', '', '', '');

  //       const structuredCount = {
  //         approveCount: approveResponse.data.emails_count,
  //         rejectCount: rejectResponse.data.emails_count,
  //         pendingCount: pendingResponse.data.emails_count,
  //         reviewCount: reviewResponse.data.emails_count,
  //       };

  //       setCounts(structuredCount);

  //       // console.log(counts);
  //     })();
  //   };

  // Email Modal Approve handler
  const handleModalApprove = async (e) => {
    e.preventDefault();
    const promoIds = [promo_id];
    await approvePromos(cookies, promoIds);
    const updatedEmailsData = emailsData.filter((emailData) => {
      return emailData.promo_id !== promo_id;
    });
    if (updatedEmailsData.length === 0) {
      //   await fetchAndSetEmailData();
    } else {
      setEmailsData(updatedEmailsData);
    }
    // setModalShow(false);
  };

  // Email Modal Reject handler
  const handleModalReject = async (e) => {
    e.preventDefault();
    const promoIds = [promo_id];
    await rejectPromos(cookies, promoIds);
    const updatedEmailsData = emailsData.filter((emailData) => {
      return emailData.promo_id !== promo_id;
    });
    if (updatedEmailsData.length === 0) {
      //   await fetchAndSetEmailData();
    } else {
      setEmailsData(updatedEmailsData);
    }
    // setModalShow(false);
  };
  // Email Modal Review Handler
  const handleModalReview = async (e) => {
    e.preventDefault();
    const promoIds = [promo_id];
    await reviewPromos(cookies, promoIds);
    const updatedEmailsData = emailsData.filter((emailData) => {
      return emailData.promo_id !== promo_id;
    });
    if (updatedEmailsData.length === 0) {
      //   await fetchAndSetEmailData();
    } else {
      setEmailsData(updatedEmailsData);
    }
    // setModalShow(false);
  };

  //   // Pagination - Row limit change handler
  //   const handleRowsLimitChange = async (rows) => {
  //     if (!currentPlatform) return;
  //     setLoading(true);
  //     setRowsLimit(rows);
  //     const offset = (pageNumber - 1) * rows;
  //     const response = await getCompanyEmailsData({
  //       cookies,
  //       currentCompany: currentPlatform,
  //       limit: rows,
  //       offset,
  //       countryCode,
  //       promotionType,
  //       category: categories,
  //       industry: industries,
  //     });
  //     setEmailsData(response.data.emails_data);
  //     setLoading(false);
  //   };

  //   // Pagination - Page change handler
  //   const handlePageChange = async (page) => {
  //     if (!currentPlatform) return;
  //     setLoading(true);
  //     setPageNumber(page);
  //     const offset = (page - 1) * rowsLimit;
  //     const response = await getCompanyEmailsData({
  //       cookies,
  //       currentCompany: currentPlatform,
  //       promotionType,
  //       countryCode,
  //       category: categories,
  //       industry: industries,
  //       offset,
  //       limit: rowsLimit,
  //     });
  //     setEmailsData(response.data.emails_data);
  //     setLoading(false);
  //   };

  //   // Row Selection handler
  //   const handleRowSelection = (state) => {
  //     const promoIds = state.selectedRows.map((row) => row.promo_id);
  //     setSelectedPromoIds(promoIds);
  //   };

  //   // Selection Approve all handler
  //   const handleApproveAll = async (e) => {
  //     e.preventDefault();
  //     await approvePromos(cookies, selectedPromoIds);
  //     const updatedEmailsData = emailsData.filter((emailData) => {
  //       return !selectedPromoIds.includes(emailData.promo_id);
  //     });
  //     if (updatedEmailsData.length === 0) {
  //       await fetchAndSetEmailData();
  //     } else {
  //       setEmailsData(updatedEmailsData);
  //     }
  //     setClearSelectedRowsToggle(!clearSelectedRowsToggle);
  //   };

  //   // Selection Reject all handler
  //   const handleRejectAll = async (e) => {
  //     e.preventDefault();
  //     await rejectPromos(cookies, selectedPromoIds);
  //     const updatedEmailsData = emailsData.filter((emailData) => {
  //       return !selectedPromoIds.includes(emailData.promo_id);
  //     });
  //     if (updatedEmailsData.length === 0) {
  //       await fetchAndSetEmailData();
  //     } else {
  //       setEmailsData(updatedEmailsData);
  //     }
  //     setClearSelectedRowsToggle(!clearSelectedRowsToggle);
  //   };

  const handleCategoryChange = (categoryId) => {
    const category = categoriesSchema.find((item) => item.id === categoryId);

    if (!category) {
      // Handle invalid category selection
      return;
    }

    setCategoryData((prevCategoryData) => {
      const updatedCategoryData = [...prevCategoryData];

      const categoryIndex = updatedCategoryData.findIndex((item) => item.id === categoryId);

      if (categoryIndex !== -1) {
        // Category exists, remove it
        updatedCategoryData.splice(categoryIndex, 1);
      } else {
        // Category doesn't exist, create it with an empty sub_collections array
        updatedCategoryData.push({
          id: category.id,
          hierarchy: category.hierarchy,
        });
      }
      return updatedCategoryData;
    });
  };

  const handleIndustryChange = (industry) => {
    const newIndustry = industriesSchema.find((item) => item.id === industry.id);

    if (!newIndustry) {
      // Handle invalid industry selection
      return;
    }

    // console.log(industry);

    setIndustryData((prevIndustry) => {
      let updatedIndustryData = [...prevIndustry];

      // If exists, remove

      const exists = updatedIndustryData.find((item) => item.id === industry.id);

      if (exists) {
        updatedIndustryData = updatedIndustryData.filter((item) => item.id !== industry.id);
      } else {
        // if not exist add
        updatedIndustryData.push({
          id: newIndustry.id,
          hierarchy: newIndustry.hierarchy,
        });
      }
      return updatedIndustryData;
    });
  };

  return (
    <div>
      <Modal className='EmailModal' show={true} onHide={() => {}} fullscreen={true}>
        <Modal.Header className='EmailModalHeader' closeButton>
          <Modal.Title>Detailed Page - {currentPlatform}</Modal.Title>
        </Modal.Header>
        <Modal.Body className='EmailModalBody'>
          <div className='ModalMetadata'>
            <Tabs defaultActiveKey={'rendered'} id='tabs-frame' className='ModalTabs'>
              <Tab eventKey='rendered' title='Rendered'>
                {/* <iframe className='ModalIframe' srcDoc={currentEmailContent.html} /> */}
                <iframe className='ModalIframe' srcDoc={editedHTML} />
              </Tab>
              <Tab eventKey='Text' title='Text'>
                <div className='EditorControls'>
                  <Button variant='primary' onClick={focusAndReplaceTextToHiddent}>
                    Hide Selection
                  </Button>
                  <Button variant='danger' onClick={clearAllChanges}>
                    Clear All Changes
                  </Button>
                  <Button id='html-update-button' variant='success' onClick={handleSave}>
                    Save
                  </Button>
                </div>
                <Editor
                  height='calc(100% - 40px)'
                  language='html'
                  theme='vs-dark'
                  value={editedHTML}
                  options={monacoEditorOptions}
                  onChange={handleEditorChange}
                  onMount={handleEditorOnMount}
                />
              </Tab>
            </Tabs>
          </div>
          <div className='ModalMetadata'>
            <Tabs defaultActiveKey={'email_data'} id='tabs' className='ModalTabs'>
              <Tab eventKey='email_data' title='Email Data'>
                <div className='ModalContainer'>
                  <Table striped={true} bordered={true} hover={true} size='sm'>
                    <tbody>
                      <tr>
                        <td>Sender Email</td>
                        <td>{currentEmailContent.sender_email}</td>
                      </tr>
                      <tr>
                        <td>Subject</td>
                        <td>{currentEmailContent.subject}</td>
                      </tr>
                      <tr>
                        <td>Country Code</td>
                        <td>{currentEmailContent.country_code}</td>
                      </tr>
                      <tr>
                        <td>Email Timestamp</td>
                        <td>{currentEmailContent.date}</td>
                      </tr>
                      <tr>
                        <td>File Path</td>
                        <td>{currentEmailContent.path}</td>
                      </tr>
                      <tr>
                        <td>Platform</td>
                        <td>{currentEmailContent.platform}</td>
                      </tr>
                      <tr>
                        <td>User ID</td>
                        <td>{currentEmailContent.user_id}</td>
                      </tr>
                      <tr>
                        <td>User Email</td>
                        <td>{currentEmailContent.user_email}</td>
                      </tr>
                      <tr>
                        <td>MID</td>
                        <td>{currentEmailContent.mid}</td>
                      </tr>
                      <tr>
                        <td>UID</td>
                        <td>{currentEmailContent.uid}</td>
                      </tr>
                      <tr>
                        <td>Mailbox</td>
                        <td>{currentEmailContent.mailbox}</td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </Tab>
              <Tab eventKey='categories' title='Categories'>
                <div className='ModalContainer'>
                  <Container>
                    <div className='DropdownControl'>
                      <div className='DropdownButton'>
                        <Form.Group controlId='categories'>
                          {/* <Form.Label>Categories Dropdown</Form.Label> */}
                          <Dropdown>
                            <Dropdown.Toggle variant='primary' id='categories-dropdown-toggle'>
                              {Object.keys(categoryData).length
                                ? `${Object.keys(categoryData).length} Collections Selected`
                                : 'Select Collection'}
                            </Dropdown.Toggle>
                            <Dropdown.Menu style={{ maxHeight: '300px', overflowY: 'scroll' }}>
                              <Form.Group controlId='categorySearch'>
                                <Form.Control
                                  type='text'
                                  placeholder='Search Categories'
                                  value={categorySearchTerm}
                                  onChange={(e) => setCategorySearchTerm(e.target.value)}
                                />
                              </Form.Group>
                              {categoriesSchema
                                ?.filter((collection) =>
                                  collection.hierarchy
                                    .toLowerCase()
                                    .includes(categorySearchTerm?.toLowerCase()),
                                )
                                .map((collection) => (
                                  <Dropdown.Item
                                    key={collection.id}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      e.stopPropagation();
                                      handleCategoryChange(collection.id);
                                    }}
                                  >
                                    {/* {console.log(collection)} */}
                                    <Form.Check
                                      readOnly
                                      className='custom-checkbox'
                                      type='checkbox'
                                      label={collection.hierarchy}
                                      checked={categoryData?.some((item) => item === collection.id)}
                                      onClick={() => {}}
                                    />
                                  </Dropdown.Item>
                                ))}
                            </Dropdown.Menu>
                          </Dropdown>
                        </Form.Group>
                      </div>
                      <div className='DropdownButton'>
                        <Form.Group controlId='subcategories'>
                          {/* <Form.Label>Subcategories Dropdown</Form.Label> */}
                          <Dropdown>
                            <Dropdown.Toggle variant='primary' id='subcategories-dropdown-toggle'>
                              {Object.keys(industryData).length
                                ? `${Object.keys(industryData).length} Industry Selected`
                                : 'Select Industry'}
                            </Dropdown.Toggle>

                            <Dropdown.Menu style={{ maxHeight: '300px', overflowY: 'scroll' }}>
                              <Form.Group controlId='subcategorySearch'>
                                <Form.Control
                                  type='text'
                                  placeholder='Search SubCollections'
                                  value={industrySearchTerm}
                                  onChange={(e) => setIndustrySearchTerm(e.target.value)}
                                />
                              </Form.Group>
                              {industriesSchema
                                ?.filter((industry) =>
                                  industry.hierarchy
                                    .toLowerCase()
                                    .includes(industrySearchTerm?.toLowerCase()),
                                )
                                .map((industry) => (
                                  <Dropdown.Item
                                    key={industry.id}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      e.stopPropagation();
                                      handleIndustryChange(industry);
                                    }}
                                  >
                                    {/* <Form.Check
                          readOnly
                          className='custom-checkbox'
                          type='checkbox'
                          label={industry.hierarchy}
                          checked={categoryData.some((item) => item.id === industry.id)}
                          onClick={() => {}}
                        /> */}
                                    {industry.hierarchy}
                                  </Dropdown.Item>
                                ))}
                            </Dropdown.Menu>
                          </Dropdown>
                        </Form.Group>
                      </div>
                      <Button
                        id='collection-update-button'
                        className='SetCategories'
                        size='small'
                        variant='success'
                        onClick={handleCollectionUpdate}
                      >
                        Set Categories
                      </Button>
                    </div>

                    <CategoryTable
                      categoryData={categoryData}
                      industryData={industryData}
                      onRemoveCategory={handleCategoryChange}
                      onRemoveIndustry={handleIndustryChange}
                    />
                  </Container>
                </div>
              </Tab>
            </Tabs>
          </div>
        </Modal.Body>
        <Modal.Footer className='EmailModalFooter'>
          <Button variant='outline-success' onClick={handleModalApprove}>
            Approve
          </Button>
          <Button variant='outline-danger' onClick={handleModalReject}>
            Reject
          </Button>
          <Button variant='outline-warning' onClick={handleModalReview}>
            Mark for Review
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default DataModal;
